import React from 'react';
import { Helmet } from "react-helmet"
import NavigationBar from '../components/navigation_bar';
import * as resumeStyles from '../components/resume.module.css';

export default class Resume extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Resume</title>
            </Helmet>
            <NavigationBar />

            <div className={resumeStyles.content}>
                <h1 className={resumeStyles.title}>Resume</h1>
                <hr className={resumeStyles.line} />
                <h2 className={resumeStyles.header}>Harry Zhou</h2>
                <p className={resumeStyles.mini}>
                    1786 Iona Ave <br/>
                    Naperville, IL 60565 <br/>
                    (224) 806-6689 <br/>
                    harryyz2@illinois.edu <br/>
                </p>

                <h3 className={resumeStyles.section}>Education</h3>
                <h4 className={resumeStyles.subsection}><b>University of Illinois at Urbana-Champaign</b></h4>
                <p className={resumeStyles.mini}>Expected graduation: May 2025</p>
                <p className={resumeStyles.mini}>Bachelor of Science in Mathematics and Computer Science</p>

                <h3 className={resumeStyles.section}>Coursework</h3>
                <ul className={resumeStyles.text}>
                    <li><em>CS 225 - Data Structures and Algorithms</em> - A+</li>
                    <li><em>CS 341 - System Programming</em> - A+</li>
                    <li><em>CS 374 - Intro to Algorithms & Models of Computation</em> - A+</li>
                    <li><em>CS 483 - Applied Parallel Programming</em> - A+</li>
                    <li><em>CS 450 - Numerical Analysis</em> - A+</li>
                    <li><em>MATH 413 - Intro to Combinatorics</em> - A+</li>
                    <li><em>MATH 461- Probability Theory</em> - A+</li>
                </ul>

                <h3 className={resumeStyles.section}>Technical Skills</h3>
                <p className={resumeStyles.text}><b>Languages:</b><br/>Java, C, C++, Python, HTML/CSS/JS, NodeJS, Go, Rust, GLSL, CUDA</p>
                <p className={resumeStyles.text}><b>Libraries/Frameworks/Systems:</b><br/>Linux, Git, Numpy, Scipy, Lombok, Tensorflow, ReactJS, OpenGL</p>

                <h3 className={resumeStyles.section}>Experience</h3>
                <h4 className={resumeStyles.subsection}><b>Argonne National Laboratory</b><i> - Research Assistant</i></h4>
                <p className={resumeStyles.mini}>September 2022 - Present</p>
                <ul className={resumeStyles.text}>
                    <li>Wrote the control system for a machine that uses magnetron sputtering for thin-film depositions</li>
                    <li>Wrote data-collection software for lab equipment</li>
                    <li>Designed and implemented data analysis software for x-ray reflectivity measurements on multilayer optics</li>
                </ul>
                <h4 className={resumeStyles.subsection}><b>International Collegiate Programming Contest</b></h4>
                <p className={resumeStyles.mini}>2022 - Present</p>
                <ul className={resumeStyles.text}>
                    <li>Worked in a team of 3 to solve advanced algorithmic problems</li>
                    <li>Practice sessions every weekend to improve team strategy and collaboration</li>
                    <li>Placed 4th in Mid-Central USA regional competition out of 74 teams</li>
                </ul>
                <h4 className={resumeStyles.subsection}><b>University of Illinois</b><i> - Course Assistant</i></h4>
                <p className={resumeStyles.mini}>January 2022 - December 2022</p>
                <ul className={resumeStyles.text}>
                    <li>Helped run a student-led course in Rust programming</li>
                    <li>Provided guidance for students on homework and projects</li>
                </ul>
                
                <h3 className={resumeStyles.section}>Project Highlights</h3>
                <h4 className={resumeStyles.subsection}><b>Procedura</b></h4>
                <ul className={resumeStyles.text}>
                    <li>Designed a framework for creating procedural structures in Minecraft</li>
                    <li>Wrote a library in Golang for creating procedural structures and communicating them to the local server</li>
                    <li>Wrote a Java plugin that dynamically registers and loads procedural creations to provide real-time feedback</li>
                </ul>
                <h4 className={resumeStyles.subsection}><b>Neural Network from Scratch</b></h4>
                <ul className={resumeStyles.text}>
                    <li>Designed and implemented a neural network machine learning library from scratch in c++</li>
                    <li>No 3rd party libraries used</li>
                    <li>Efficient backpropagation and optimizer implementation</li>
                </ul>
                <h4 className={resumeStyles.subsection}><b>CUDA Fractal Renderer</b></h4>
                <ul className={resumeStyles.text}>
                    <li>A configurable GPU-powered fractal animation renderer written in CUDA c++</li>
                    <li>Produces a series of animation frames that can be compiled into a video with ffmpeg</li>
                </ul>
                <h4 className={resumeStyles.subsection}><b>Sound Characterization Machine Learning Project</b></h4>
                <ul className={resumeStyles.text}>
                    <li>A machine learning project to determine the pitch and timing of noteblock notes from audio data</li>
                    <li>Written with tensorflow</li>
                </ul>
                <h3 className={resumeStyles.section}>Awards/Honors</h3>
                <p className={resumeStyles.text}><b>ICPC North American Qualifier (2023)</b> - 4th among UIUC particpants</p>
                <p className={resumeStyles.text}><b>Mid-Central USA Regionals (2022)</b> -  4th out of 74 mid-central USA teams</p>
                <p className={resumeStyles.text}><b>Dean's List (2021-2024)</b></p>
                <br/>
            </div>
        </div>;
    }
}
