// extracted by mini-css-extract-plugin
export var content = "resume-module--content--b7VsB";
export var header = "resume-module--header--tP4fq";
export var light = "resume-module--light--mDYzX";
export var line = "resume-module--line--r+Ofh";
export var link = "resume-module--link--cGp4F";
export var mini = "resume-module--mini--Ir9Ge";
export var section = "resume-module--section--hlC3z";
export var smallsection = "resume-module--smallsection--FySsi";
export var subsection = "resume-module--subsection--Ru81Q";
export var text = "resume-module--text--cWt86";
export var title = "resume-module--title--KsWYb";